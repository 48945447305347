import { AfterViewInit, Injectable, OnDestroy, inject } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService implements OnDestroy {

  private url = environment.apiSocketUrl;
  private pathSocket = environment.pathSocket;
  public socket?: Socket;
  public messages$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {

    // Suscribe a los cambios de estado de la red
    window.addEventListener('online', this.handleConnectionChanged);
    window.addEventListener('offline', this.handleConnectionChanged);

    // Intenta conectarte al servidor de Socket.IO si el usuario está en línea
    if (navigator.onLine) {
      this.connectToServer();
    }
  }

  ngOnDestroy() {
    // Desconectar Socket.IO cuando se destruye el componente
    this.disconnectFromServer();
    window.removeEventListener('online', this.handleConnectionChanged);
    window.removeEventListener('offline', this.handleConnectionChanged);
  }

  /**
   * Manejar los cambios de estado de la red
   */
  handleConnectionChanged = (event: Event) => {
    if (event.type === 'online') {
      this.connectToServer();
    } else if (event.type === 'offline') {
      this.disconnectFromServer();
    }
  };

  /**
   * Ejecuta la conexión al servidor de Socket.IO
   */
  connectToServer() {

    // Obtener el token actualizado del localStorage
    const token = localStorage.getItem('accessToken');

    // Conectarse al servidor de Socket.IO
    this.socket = io(this.url, {
      path: this.pathSocket,
      transports: ['websocket'],
      query: { token: token },
      extraHeaders: {
        token: "Bearer " + token,
      },
    });

    // Escuchar los mensajes del servidor de Socket.IO
    this.socket.on('assistants_update', (message: any) => {
      this.messages$.next(message);
    });

    // Manejar los errores
    this.socket.on('error', (error: string) => {
      console.error(`Error: ${error}`);
    });
  }

  /**
   * Desconectarse del servidor de Socket.IO
   */
  disconnectFromServer() {
    if (this.socket) {
      this.socket.disconnect();
      this.messages$.next(null);
    }
  }

  /**
   * Enviar un mensaje al servidor de Socket.IO
   */
  public sendMessage(message: any) {
    this.socket?.emit('messageFront', message);
  }

  /**
   * Escuchar los mensajes del servidor de Socket.IO
   */
  public get listenMessages(): Observable<any> {
    return this.messages$.asObservable();
  }
  
}
