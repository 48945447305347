import { inject, Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { MarkdownService } from 'ngx-markdown';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Subject,
  takeUntil,
} from 'rxjs';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { TOAST_DEFAULTS } from 'src/app/core/constants/toast-defaults.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UserData } from 'src/app/core/models/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { WebSocketService } from 'src/app/core/services/v2-socket.io.service';
import { ActivityFile } from 'src/app/features/law-firm/interfaces/activity.interfaces';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { environment } from 'src/environments/environment';
import Swal, { SweetAlertResult } from 'sweetalert2';
import Typed from 'typed.js';
import { v4 as uuidv4 } from 'uuid';
import { UisrAuthService } from '../../../../core/services/uisr-auth.service';
import { ChatThreadsComponent } from '../components/chat-threads/chat-threads.component';
import { ChatWindowComponent } from '../components/chat-window/chat-window.component';
import device from 'current-device';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AssistantChatService {
  // readonly betaWorkspaces: number[] = environment.assistantAccess;
  readonly production: boolean = environment.production;
  readonly resources = RESOURCES;
  readonly hostUrl: string = ''; // Ruta host para renderizar el componente al 100% de la pantalla

  showInfo: boolean = false;
  showChips = true;

  models: any[] = [];

  userData?: UserData;
  instance: any;
  unsubscribe = new Subject<void>();
  updateMessage = new Subject();

  threadsComponent!: ChatThreadsComponent;
  chatWindowComponent!: ChatWindowComponent;

  totalThreads: number | null = null;
  totalMessages: number | null = null;
  threadsPage: number = 1;
  messagesPage: number = 1;
  globalStatus = true; // Si el asistente está habilitado globalmente
  workspaceStatus = true; // Si el asistente está habilitado para el despacho del usuario en sesión

  thread: any = null;
  model: string = 'gpt-4o';
  threads: any[] = [];
  documents: any[] = [];
  messages: any[] = [];

  runSteps: any[] = [];

  public componentStates: any = {
    threadsMenu: true, // Visible el listado de conversaciones
    maximize: false, // Esta maximixada la ventana del chat
    close: true, // Esta cerrada la ventana del chat
    onHostRoute: false, // Si la ruta es la de la ventana del chat
  };

  loadingStates = {
    activeRun: new BehaviorSubject<string | null>(null), // Hay un proceso activo
    loadingModels: new BehaviorSubject<boolean>(false), // Cargando los modelos llm
    loadingThreads: new BehaviorSubject<boolean>(false), // Cargando las conversaciones
    creatingThread: new BehaviorSubject<boolean>(false), // Creando una nueva conversación
    loadingThread: new BehaviorSubject<boolean>(false), // Cargando una conversación (unsubscribe)
    loadingThreadMessages: new BehaviorSubject<boolean>(false), // Cargando los mensajes de una conversación (unsubscribe)
    sendingMessage: new BehaviorSubject<boolean>(false), // Enviando un nuevo mensaje (unsubscribe)
    addingDocuments: new BehaviorSubject<boolean>(false), // Cargando un nuevo documento (unsubscribe)
    deletingDocuments: new BehaviorSubject<boolean>(false), // Eliminando un documento (unsubscribe)
  };

  private readonly authService = inject(UisrAuthService);
  private readonly apiService = inject(UisrApiServiceV2);
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly markdownService = inject(MarkdownService);
  private readonly socketService = inject(WebSocketService);

  constructor() {
    this.markdownBoosters();

    // Suscribirse a los datos del usuario
    this.store
      .pipe(
        select(UserDataFull),
        distinctUntilChanged(isEqual),
        untilDestroyed(this)
      )
      .subscribe(async (data) => {
        this.userData = data;
      });

    // Subscribirse a eventos de las rutas
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.onDemandRouterStates(event.url);
      }

      if (event instanceof NavigationEnd) {
        this.onDemandRouterStates(event.urlAfterRedirects);
      }
    });

    // Subscribirse a eventos del socket
    this.socketService.listenMessages.subscribe((data: any) => {
      this.onSocketEvents(data);
    });

    this._subscribeToFeatureConfig();
    this._subscribeToFeatureAccess();
    this._subscribeToWorkspaceSettings();
  }

  ///////////////////////////////////////////////////
  ////////// MISC

  /** Se suscribe a la configuración global del asistente determinada por MiDespacho */
  private _subscribeToFeatureConfig() {
    this.authService.featureConfig
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        const assistantConfig = data?.find((item) => item.label == 'ASSISTANT');
        if (assistantConfig) {
          this.globalStatus = assistantConfig.status;
        }
      });
  }

  /** Se suscribe a la información de feature_access que determina si el despacho puede acceder al asistente */
  private _subscribeToFeatureAccess() {
    this.authService.featureAccess
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data?.access) {
          const assistantConfig = data.access['assistant'];
          if (assistantConfig != null && assistantConfig != undefined) {
            this.workspaceStatus = assistantConfig;
          }
        }
      });
  }

  /** Se suscribe a los valores configurados a nivel del despacho. Ej: el modelo configurado para todo el despacho */
  private _subscribeToWorkspaceSettings() {
    this.authService.assistantSettings
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data?.model) {
          this.setModel(data.model);
        }
      });
  }

  /**
   * Valida si el asistente esta disponible para el usuario
   * Siempre disponible para LOCAL, DEV y QA
   */
  assistantAvailable(): boolean {
    return (
      this.globalStatus 
      // && (this.production
      //   ? this.betaWorkspaces.includes(this.userData?.idWorkspace || -1)
      //   : true)
    );
  }

  /**
   * Observar eventos de las rutas
   */
  onDemandRouterStates(route: string) {
    if (route != this.hostUrl) {
      this.componentStates.onHostRoute = false;
      this.componentStates.maximize = false;
    } else {
      this.componentStates.onHostRoute = true;
      this.componentStates.maximize = true;
      this.componentStates.close = false;
    }
  }

  /**
   * Observar eventos del socket
   */
  onSocketEvents(event: any) {
    if (!event) return;

    switch (event.type) {
      case 'insert_run':
      case 'update_run':
        // Si el run corresponde a la conversacion activa
        if (event.payload.thread_id == this.thread?.threadId) {
          // Si el nuevo run es diferente al actual se culmina el actual
          if (
            event.payload.status == 'completed' ||
            this.loadingStates.activeRun.value != event.payload.run_id
          ) {
            this.clearRun();
          }

          if (event.payload.status == 'in_progress') {
            this.setRun(event.payload.run_id);
          }

          if (event.payload.status == 'failed') {
            this.clearRun();
            const message = this.messages.find((m) => m.runId == event.payload.run_id)
            if (message) {
              message.error = true;
            }
          }
        }

        break;

      case 'insert_thread':
      case 'update_thread':
        // Si tiene id y nombre de la conversación
        // Se valida que no exista en la lista y
        // se inserta la nueva conversacion
        if (event.payload.thread_id && event.payload.thread_name) {
          const thread = this.threads.find(
            (thread: any) => thread.threadId == event.payload.thread_id
          );

          if (!thread) {
            //Ejecutar animacion de creacion de la conversación
            this.pushNewThread({
              name: event.payload.thread_name,
              threadId: event.payload.thread_id,
            });
          }
        }

        break;

      case 'insert_message':
        // Si el mensaje corresponde a la conversacion activa
        if (event.payload.thread_id == this.thread?.threadId) {
          // Al recibir mensaje del usuario
          if (event.payload.role == 'user') {
          }

          // Al recibir mensaje del asistente
          if (event.payload.role == 'assistant') {
            // Obtener la data del mensaje
            this.fetchThreadMessage(event.payload.message_id);
          }
        }

        break;

      case 'insert_message_step':
        // Si el paso corresponde a la conversacion activa
        if (event.payload.thread_id == this.thread?.threadId) {
          // Si corresponde al run actual
          if (event.payload.run_id == this.loadingStates.activeRun.value) {
            // Si el paso tiene mensaje para mostrar
            if (event.payload.display_message) {
              this.runSteps.push(event.payload);
              this.scrollToBottom();
            }
          }
        }

        break;

      default:
        break;
    }
  }

  ///////////////////////////////////////////////////
  ////////// Markdown

  /**
   * Cargar métodos especiales para enriquecer el markdown / Ejecutar metodos que permiten extender las capacidades del markdown
   */
  markdownBoosters() {
    this.customWidgetHook();
    this.internalUrlRouter();
  }

  /**
   * Detectar etiquetas html especiales para renderizar widget de angular dentro del markdown
   */
  customWidgetHook() {
    this.markdownService.renderer.html = (html: string) => {

      // Se deben recibir los widget con este formato:
      // <meta widget="true" data='{"widget":"calendar"}'>

      const widgetError = `
        <div class="flex items-center gap-2 px-2 h-8 w-fit min-w-[2rem] min-h-[2rem] cursor-not-allowed place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm text-sm group my-5">
          <i class="fa-duotone fa-microchip-ai opacity-75 group-hover:opacity-100" style="font-size: 1rem;"></i>
          Error en Widget
        </div>`;

      const widgetLoading = `
        <div widget="true" data='{"widget":"calendar"}' class="flex items-center gap-2 px-2 h-8 w-fit min-w-[2rem] min-h-[2rem] cursor-pointer place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm text-sm group my-5">
          <i class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin opacity-75 group-hover:opacity-100" style="font-size: 1rem;"></i>
          Cargando Widget
        </div>`;

      // Verificar si el string contiene widget="true"
      const widgetRegex = /widget="true"/;
      const hasWidget = widgetRegex.test(html);

      if (hasWidget) {
        // Expresión regular para capturar el valor de la propiedad data
        const dataRegex = /(data="({[^"]+})"|data='({[^']+})')/;
        const match = html.match(dataRegex);

        if (match) {
          // Extraer el JSON dentro de la propiedad data
          const jsonData = match[2] || match[3];

          try {
            // Aca se debe implementar un metodo de renderizacion del widget
            JSON.parse(jsonData);
            return widgetLoading;
          } catch (error) {
            return widgetError;
          }
        } else {
          return widgetError;
        }
      }

      return html;
    };
  }

  /**
   * Construye el widget para router de urls internas
   * El widget permite navegar internamente sin hacer target blank
   */
  internalUrlRouter() {
    this.markdownService.renderer.link = (
      href: string,
      title: string,
      text: string
    ) => {
      if (
        environment.environmentsHosts.some((environment: string) => {
          return href.includes(environment);
        })
      ) {
        return `<a widget="true" data='{"widget":"internalRouter","href":"${this.internalUrlReplacer(
          href
        )}","title":"${title}","text":"${text}"}' ${
          title ? `title="${title}"` : ''
        } role="button" href="${href}">${text}</a>`;
      }

      return `<a ${title ? `title="${title}"` : ''} href="${href}">${text}</a>`;
    };
  }

  ///////////////////////////////////////////////////
  ////////// Estados del UI

  /**
   * Maximizar chat
   */
  maximize() {
    this.componentStates.maximize = true;
  }

  /** Minimizar chat */
  minimize() {
    this.componentStates.maximize = false;
  }

  /** Abre el chat */
  open() {
    this.componentStates.close = false;
  }

  /** Cerrar chat */
  close() {
    this.minimize();
    this.componentStates.close = true;
    this.showChips = true;
  }

  /** Apertura/cierre del asistente */
  toggle() {
    if (this.componentStates.close) {
      this.open();
    } else {
      this.close();
    }
  }

  /** Mostrar información del asistente */
  showInfoContent() {
    this.deactivateThread();
    this.showInfo = true;
  }

  /** Ocultar información del asistente */
  hideInfoContent() {
    this.showInfo = false;
  }

  ///////////////////////////////////////////////////
  ////////// Estados del UI

  /**
   * Obtener catalogo de modelos
   */
  fetchModels() {
    this.apiService
      .get(this.resources.assistantModels, {})
      .pipe(
        untilDestroyed(this),
        loadingState(this.loadingStates.loadingModels)
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.models = res.data;
          }
        },
        error: (error: any) => {
          // Implementar
        },
      });
  }

  /** Establecer modelo */
  setModel(modelId: string) {
    // Si hay una conversacion activa
    if (this.thread?.threadId) {
      this.setThreadModel(modelId);
    } else {
      this.setLocalModel(modelId);
    }
  }

  /** Establecer modelo para la conversacion existente */
  setThreadModel(modelId: string) {
    let threadId = this.thread?.threadId;

    this.apiService
      .patch(`${this.resources.assistantThread}/${threadId}`, {
        model_id: modelId,
      })
      .pipe(
        untilDestroyed(this),
        takeUntil(this.unsubscribe),
        loadingState(this.loadingStates.loadingModels)
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.model = modelId;
          }
        },
        error: (error: any) => {
          // Implementar
        },
      });
  }

  /** Establecer modelo para la nueva conversacion */
  setLocalModel(modelId: string) {
    this.model = modelId
      ? modelId
      : this.authService.assistantSettings?.value?.model || 'gpt-4o';
  }

  ///////////////////////////////////////////////////
  ////////// Runs

  /**
   * Obtener los mensajes de una conversacion
   */
  fetchThreadRun(threadId: any) {
    let firstLoad = this.messagesPage > 1 ? false : true;

    this.apiService
      .get(`${this.resources.assistantThread}/${threadId}/messages`, {
        pageSize: 10,
        pageNumber: this.messagesPage,
      })
      .pipe(
        untilDestroyed(this),
        takeUntil(this.unsubscribe),
        loadingState(
          firstLoad
            ? this.loadingStates.loadingThread
            : this.loadingStates.loadingThreadMessages
        )
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            // Total de mensajes en la conversación
            this.totalMessages = res.total;

            // Agregar mensajes al array
            res.data.forEach((message: any) => {
              this.messages.unshift({
                id: message.message_id,
                role: message.role,
                loading: false,
                error: false,
                content: this.markdownService.parse(message.content),
                created_at: message.timestamp,
              });
            });

            // Colocar scroll hasta el nuevo mensaje
            if (firstLoad) {
              this.scrollToBottom();
            }

            // Comprobar carga automatica de la siguiente pagina
            setTimeout(() => {
              this.chatWindowComponent.checkThreadMessagesScroll();
            }, 0);
          }
        },
        error: (error: any) => {
          // Implementar
        },
      });
  }

  /**
   * Limpiar run
   */
  clearRun() {
    this.loadingStates.activeRun.next(null);
    this.runSteps = [];
  }

  /**
   * Establecer run actualmente activo en la conversacion activa
   */
  setRun(runId: string) {
    this.loadingStates.activeRun.next(runId);
    this.runSteps = [];
  }

  ///////////////////////////////////////////////////
  ////////// Chat

  /**
   * Enviar mensaje a la conversacion o crear una nueva
   */
  sendMessage(message: string) {
    // Si hay una conversacion activa
    if (this.thread?.threadId) {
      this.sendThreadMessage(message);
    } else {
      this.createThread(message);
    }
  }

  /** Enviar mensaje a una conversación existente */
  sendThreadMessage(message: string) {
    let threadId = this.thread?.threadId;
    let newMessageId = uuidv4();

    // Agregar mensaje de usuario
    this.messages.push({
      id: newMessageId,
      role: 'user',
      loading: true,
      content: this.markdownService.parse(message),
      created_at: DateTime.now().toUTC().toISO(),
      runId: null,
    });

    // Colocar scroll hasta el nuevo mensaje
    this.scrollToBottom();

    // Enviar mensaje a la conversacion
    this.apiService
      .post(this.resources.assistantThread, {
        user_prompt: message,
        thread_id: threadId,
      })
      .pipe(
        untilDestroyed(this),
        takeUntil(this.unsubscribe),
        loadingState(this.loadingStates.sendingMessage)
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            // Si pertenece a la conversacion activa (no salio de la conversacion mientras se enviaba el mensaje)
            if (res.data.completion.thread_id == this.thread?.threadId) {
              // Establecer el run activo actual
              this.setRun(res.data.completion.run_id);

              // Mensaje original
              let message = this.messages.find((m) => m.id == newMessageId);

              if (message) {
                // Reiniciar loading y asignar id
                message.loading = false;
                message.runId = res.data.completion.run_id;
              }
            }
          }
        },
        error: (error: any) => {
          // Mensaje original
          let message = this.messages.find((m) => m.id == newMessageId);
          if (message) {
            // Reiniciar loading y asignar error
            message.loading = false;
            message.error = true;
          }
        },
      });
  }

  /**
   * Crear nueva conversacion
   */
  createThread(message: string) {
    let newMessageId = uuidv4();

    // Agregar mensaje de usuario
    this.messages.push({
      id: newMessageId,
      role: 'user',
      loading: true,
      content: this.markdownService.parse(message),
      created_at: DateTime.now().toUTC().toISO(),
      runId: null,
    });

    // Colocar scroll hasta el nuevo mensaje
    this.scrollToBottom();

    // Datos de la petición
    let serviceData: any = {
      user_prompt: message,
    };

    // Si hay documentos preseleccionados
    if (this.documents[0]) {
      serviceData.files = this.documents;
    }

    // Si hay modelo de asistente preseleccionado
    if (this.model) {
      serviceData.model_id = this.model;
    }

    // Crear nueva conversacion
    this.apiService
      .post(this.resources.assistantThread, serviceData)
      .pipe(
        untilDestroyed(this),
        loadingState(this.loadingStates.creatingThread),
        loadingState(this.loadingStates.sendingMessage)
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            // Si no hay conversacion activa (no salio de la conversacion mientras se creaba)
            if (!this.thread) {
              // Establecer el run activo actual
              this.setRun(res.data.completion.run_id);

              // Establecer nueva conversacion como la actual
              this.thread = {
                name: null,
                threadId: res.data.completion.thread_id,
              };

              // Mensaje original
              let message = this.messages.find((m) => m.id == newMessageId);

              if (message) {
                // Reiniciar loading y asignar id
                message.loading = false;
                message.runId = res.data.completion.run_id;
              }
            }
          }
        },
        error: (error: any) => {
          // Mensaje original
          let message = this.messages.find((m) => m.id == newMessageId);
          if (message) {
            // Reiniciar loading y asignar error
            message.loading = false;
            message.error = true;
          }
        },
      });
  }

  /** Obtener los mensajes de una conversacion */
  fetchThreadMessages(threadId: any) {
    let firstLoad = this.messagesPage > 1 ? false : true;

    this.apiService
      .get(`${this.resources.assistantThread}/${threadId}/messages`, {
        pageSize: 10,
        pageNumber: this.messagesPage,
      })
      .pipe(
        untilDestroyed(this),
        takeUntil(this.unsubscribe),
        loadingState(
          firstLoad
            ? this.loadingStates.loadingThread
            : this.loadingStates.loadingThreadMessages
        )
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            // Total de mensajes en la conversación
            this.totalMessages = res.total;

            // Agregar mensajes a la conversación
            this.addRawMessages(res.data, threadId);

            // Colocar scroll hasta el nuevo mensaje
            if (firstLoad) {
              this.scrollToBottom();
            }

            // Comprobar carga automatica de la siguiente pagina
            setTimeout(() => {
              this.chatWindowComponent.checkThreadMessagesScroll();
            }, 0);
          }
        },
        error: (error: any) => {
          // Implementar
        },
      });
  }

  /** Obtener mensaje de la conversacion */
  fetchThreadMessage(messageId: any) {
    this.apiService
      .get(`${this.resources.assistantMessage}/${messageId}`)
      .pipe(
        untilDestroyed(this),
        takeUntil(this.unsubscribe),
        loadingState(this.loadingStates.sendingMessage)
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            // Si pertenece a la conversacion activa
            if (res.data.thread_id == this.thread?.threadId) {
              // Agregar mensaje de respuesta del asistente
              if (res.data.role == 'assistant') {
                // Se consulta los steps desde el run y se reasignan para mantener el procesamiento
                res.data.messageSteps = res.data.run.messageSteps;

                // Insertar mensaje del asistente con la animacion dr escritura
                this.pushAssistantMessages([res.data], res.data.thread_id);

                // Colocar scroll hasta el nuevo mensaje
                this.scrollToBottom();
              }
            }
          }
        },
        error: (error: any) => {
          // Implementar
        },
      });
  }

  /** Obtener la siguiente pagina de mensajes */
  fetchNextMessages() {
    // Si esta cargando actualmente se ignora
    if (
      this.loadingStates.loadingThread.value ||
      this.loadingStates.loadingThreadMessages.value
    ) {
      return;
    }

    // Si ya se tiene el total de mensajes se ignora
    if (this.messages.length >= (this.totalMessages || 0)) {
      return;
    }

    // Incrementar el numero de pagina
    this.messagesPage = this.messagesPage + 1;

    // Obtener Mensajes
    this.fetchThreadMessages(this.thread?.threadId);
  }

  /** Reintentar enviar un mensaje fallido */
  retryMessage(messageId: any) {
    let index = -1;
    let message = this.messages.find((m, i) => {
      if (m.id == messageId) {
        index = i;
      }

      return m.id == messageId;
    });

    if (message.runId) {
      // borrar de la base de datos
    }

    // remover mensaje erroneo
    this.messages.splice(index, 1);

    // Reintentar mensaje
    this.sendMessage(message.content);
  }

  /** Agregar mensajes a la conversacion activa */
  addRawMessages(messages: any[], threadId: string, push: boolean = false) {
    // Si no es la conversacion actual se ignora
    if (this.thread?.threadId != threadId) return;

    // Agregar mensajes al array
    messages.forEach((message: any) => {
      const parsed = {
        id: message.message_id,
        run_id: message.run_id,
        role: message.role,
        loading: false,
        error: message.run?.status == 'failed' ? true: false,
        content: this.markdownService.parse(message.content),
        created_at: message.timestamp,
        steps: message.messageSteps,
        typedId: message.typedId,
      };

      if (push) {
        this.messages.push(parsed);
      } else {
        this.messages.unshift(parsed);
      }
    });

    // Agrupar los messages steps según el run
    this.processRunSteps(threadId);
  }

  /**
   * Ejecutar animacion de reproduccion de escritura del asistente
   */
  async pushAssistantMessages(messages: any[], threadId: string) {
    // Esta promesa se completa al finalizar la animacion de escritura
    const typedAnimation = (
      typedId: string,
      content: string,
      messageId: string
    ) => {
      new Promise<void>((r) => {
        setTimeout(() => {
          // https://github.com/mattboldt/typed.js/
          const typed = new Typed(`#${typedId}`, {
            strings: [content],
            showCursor: false,
            typeSpeed: 1,
            autoInsertCss: false,
            onComplete: (self) => {
              // Remover typedId para preservar estructura original del mensaje
              // y prevenir error del maquetado al ejecutar el metodo destroy();
              const message = this.messages.find(
                (message: any) => message.id == messageId
              );

              if (message) {
                delete message.typedId;
              }

              self.destroy();
              r();
            },
          });
        }, 0);
      });
    };

    // Iterar sobre cada mensaje nuevo del asistente
    // 1. Insertar mensaje
    // 2. Reproducir animacion de escritura
    // 3. Esperar que se complete animacion
    // 4. (si existe otro mensaje) repetir
    for (let i = 0; i < messages.length; i++) {
      // Si la conversacion cambia en algun momento se finaliza
      if (threadId != this.thread?.threadId) {
        return;
      }

      const message = messages[i];
      const typedId = `typed-${uuidv4()}`;

      message.typedId = typedId;

      this.addRawMessages([message], threadId, true);

      await typedAnimation(
        typedId,
        this.markdownService.parse(message.content),
        message.message_id
      );
    }
  }

  /**
   * Agrupa los message steps por run dentro de la conversacion
   */
  processRunSteps(threadId: string) {
    // Si no es la conversacion actual se ignora
    if (this.thread?.threadId != threadId) return;

    // Reiniciar los runs
    this.thread.runs = [];

    // Procesar todos los mensajes para agrupar los message steps por run
    this.messages.forEach((message: any) => {
      const { run_id, steps } = message;

      // Si el run aun no existe se crea
      let run = this.thread.runs.find((run: any) => run.run_id == run_id);

      if (!run) {
        this.thread.runs.push({
          run_id,
          steps: [],
          citations: [],
        });

        run = this.thread.runs.find((run: any) => run.run_id == run_id);
      }

      // Ordenar los steps por timestamp antes de agregarlos
      const sortedSteps = steps?.sort((a: any, b: any) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });

      // Procesar las citas del run en objetos manejables por el frontend
      const citations: any[] = [];
      sortedSteps?.forEach((step: any) => {
        // Si tiene fuentes o citas
        if (step.citation) {
          switch (step.citation.function) {
            // Notion del centro de ayuda
            case 'ayudante_tutoriales_plataforma':
              step.citation.bibliografia.forEach((citation: any) => {
                // Agregar a las fuentes o citas
                citations.push({
                  type: 'ayudante_tutoriales_plataforma',
                  name: citation.nombre,
                  sourceUrl: citation.url_notion,
                  internalUrl: this.internalUrlReplacer(
                    citation.url_plataforma
                  ),
                });
              });

              break;

            // Documentos cargados o fragmentos de sentencias
            case 'buscador_info_documento':
              // Si es de fragmentos
              if (step.citation.bibliografia?.tipo == 'fragmentos') {
                let pages: any[] = [];

                // Iterar sobre los chunks y las paginas para reordenarlas
                Object.keys(step.citation.bibliografia.chunk_id).forEach(
                  (chunk: any) => {
                    step.citation.bibliografia.chunk_id[chunk].paginas.forEach(
                      (page: any) => {
                        pages.push({
                          pageNum: page,
                          chunk: chunk,
                        });
                      }
                    );
                  }
                );

                // Agregar a las fuentes o citas
                citations.push({
                  type: 'buscador_info_documento_fragmentos',
                  name: step.citation.bibliografia.nombre_documento,
                  pages: pages,
                });
              }

              // Si es de documento
              if (step.citation.bibliografia?.tipo == 'documento_completo') {
                // Agregar a las fuentes o citas
                citations.push({
                  type: 'buscador_info_documento_completo',
                  name: step.citation.bibliografia.nombre_documento,
                  pages: step.citation.bibliografia.paginas,
                  docId: step.citation.bibliografia.doc_id,
                });
              }

              break;
            default:
              break;
          }
        }
      });

      // Si no existe mas de 1 step se ignora
      if (!sortedSteps) {
        return;
      }

      // YA SE!
      if (!sortedSteps[2]) {
        return;
      }

      // Agregar messageSteps al run correspondiente
      run.steps.push(...sortedSteps);

      // Agregar citas al run correspondiente
      run.citations.push(...citations);
    });
  }

  /**
   * Retorna los steps de un run en caso de existir
   */
  stepsByRunId(runId: string) {
    return (
      this.thread?.runs?.find((run: any) => run.run_id == runId)?.steps || []
    );
  }

  /**
   * Retorna los citations de un run en caso de existir
   */
  citationsByRunId(runId: string) {
    return (
      this.thread?.runs?.find((run: any) => run.run_id == runId)?.citations ||
      []
    );
  }

  /**
   * Colocar scroll hasta el principio
   */
  scrollToBottom() {
    setTimeout(() => {
      this.chatWindowComponent.messagesWrapper.nativeElement.scrollTop = 9e9;
    }, 0);
  }

  /**
   * Reemplaza urls internas por url manejables por el router de angular
   */
  internalUrlReplacer(url: string) {
    let replaced = url;

    environment.environmentsHosts.forEach((host) => {
      replaced = replaced.replaceAll(host, '');
    });

    return replaced;
  }

  ///////////////////////////////////////////////////
  ////////// Conversaciones

  /** Activar una conversacion */
  activateThread(threadId: any) {
    if (threadId == this.thread?.threadId) {
      return;
    }

    this.deactivateThread();
    this.thread = this.threads.find((t: any) => t.threadId == threadId);
    if (!device.desktop()) {
      this.componentStates.threadsMenu = false;
    }

    this.fetchThread(threadId, true, true);
  }

  /** Desactivar una conversacion */
  deactivateThread() {
    this.hideInfoContent();
    this.unsubscribe.next();
    this.thread = null;
    this.model = this.authService.assistantSettings?.value?.model || 'gpt-4o';
    if (!device.desktop()) {
      this.componentStates.threadsMenu = false;
    }

    this.documents = [];
    this.messages = [];
    this.clearRun();
  }

  /** Obtener una conversacion (Opcionalmente la primer pagina de mensajes y los documentos) */
  fetchThread(
    threadId: any,
    messages: boolean = false,
    documents: boolean = false
  ) {
    this.apiService
      .get(`${this.resources.assistantThread}/${threadId}`, {
        documents: documents,
        messages: messages,
      })
      .pipe(
        untilDestroyed(this),
        takeUntil(this.unsubscribe),
        loadingState(this.loadingStates.loadingThread),
        loadingState(this.loadingStates.addingDocuments),
        loadingState(this.loadingStates.loadingModels)
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            // Establecer el modelo
            const model = this.models.find(
              (model: any) => res.data.model_id == model.model_id
            );

            this.model = model ? res.data.model_id : 'gpt-4o';

            // Si se solicito la primer pagina de mensajes
            if (messages && res.data.messages) {
              // Total de mensajes en la conversación
              this.totalMessages = res.data.messages.total;

              // Agregar mensajes a la conversación
              this.addRawMessages(res.data.messages.data, threadId);
            }

            // Si se solicito todos los documentos
            if (documents && res.data.documents) {
              let docs: any[] = [];

              res.data.documents?.forEach((doc: any) => {
                docs.push({
                  id: doc.idActivityFile || doc.id_open_search,
                  idActivityFile: doc.idActivityFile,
                  index: doc.fk_id_index,
                  name: doc.name || doc.heading,
                });
              });

              this.documents = [
                ...new Set((this.documents || []).concat(docs)),
              ];
            }

            // Colocar scroll hasta el nuevo mensaje
            this.scrollToBottom();

            // Comprobar carga automática de la siguiente pagina
            setTimeout(() => {
              this.chatWindowComponent.checkThreadMessagesScroll();
            }, 0);
          }
        },
        error: (error: any) => {
          // Implementar
        },
      });
  }

  /** Obtener conversaciones del usuario */
  fetchThreads() {
    this.apiService
      .get(this.resources.assistantUserThreads, {
        pageSize: 10,
        pageNumber: this.threadsPage,
      })
      .pipe(
        untilDestroyed(this),
        loadingState(this.loadingStates.loadingThreads)
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            // Total de conversaciones
            this.totalThreads = res.total;

            // Agregar conversaciones al array
            res.data.forEach((thread: any) => {
              // Si la conversacion no existe en el listado actual
              if (
                !this.threads.find((t: any) => t.threadId == thread.thread_id)
              ) {
                this.threads.push({
                  threadId: thread.thread_id,
                  name: thread.thread_name,
                  created_at: thread.start_time,
                });
              }
            });

            // Comprobar carga automatica de la siguiente pagina
            setTimeout(() => {
              this.threadsComponent?.checkThreadsScroll();
            }, 0);
          }
        },
        error: (error: any) => {
          // Implementar
        },
      });
  }

  /**
   * Obtener la siguiente pagina de conversaciones
   */
  fetchNextThreads() {
    // Si esta cargando actualmente se ignora
    if (this.loadingStates.loadingThreads.value) {
      return;
    }

    // Si ya se tiene el total de conversaciones se ignora
    if (this.threads.length >= (this.totalThreads || 0)) {
      return;
    }

    // Incrementar el numero de pagina
    this.threadsPage = this.threadsPage + 1;

    // Obtener conversaciones
    this.fetchThreads();
  }

  /** Eliminar conversacion */
  deleteThread(threadId: string) {
    Swal.fire({
      ...ALERT_DEFAULTS,
      ...{
        title: 'Confirmación Requerida',
        icon: 'question',
        text: '¿Deseas eliminar esta conversacion?',
        showCancelButton: true,
        showConfirmButton: true,
      },
    }).then((res: SweetAlertResult<any>) => {
      if (res.isConfirmed) {
        this.apiService
          .delete(`${this.resources.assistantThread}/${threadId}`, {})
          .pipe(
            untilDestroyed(this),
            loadingState(this.loadingStates.loadingThreads)
          )
          .subscribe({
            next: () => {
              if (this.thread?.threadId == threadId) {
                this.deactivateThread();
              }

              const deleted = this.threads.findIndex(
                (thread: any) => thread.threadId == threadId
              );
              this.threads.splice(deleted, 1);
              this.totalThreads = (this.totalThreads || 1) - 1;

              const threadsPerPage = 10;
              const isLastThreadOnPage =
                this.totalThreads % threadsPerPage === 0 &&
                this.threadsPage > 1;

              if (isLastThreadOnPage) {
                this.threadsPage = Math.max(1, this.threadsPage - 1);
              }
            },
          });
      }
    });
  }

  /**
   * Reproducir animacion de la nueva conversacion
   */
  async pushNewThread(data: any) {
    // Esta promesa se completa al finalizar la animacion de escritura
    const typedAnimation = (
      typedId: string,
      content: string,
      threadId: string
    ) => {
      new Promise<void>((r) => {
        setTimeout(() => {
          // https://github.com/mattboldt/typed.js/
          const typed = new Typed(`#${typedId}`, {
            strings: [content],
            showCursor: false,
            typeSpeed: 30,
            autoInsertCss: false,
            onComplete: (self) => {
              // Remover typedId para preservar estructura original del elemento
              // y prevenir error del maquetado al ejecutar el metodo destroy();
              if (this.threads[0]) {
                const thread = this.threads.find(
                  (thread: any) => thread.threadId == threadId
                );

                if (thread) {
                  delete thread.typedId;
                }
              }

              self.destroy();
              r();
            },
          });
        }, 300); // <-- Este es el tiempo adecuando segunn la animacion "-intro-x" del elemento en el maquetado
      });
    };

    // Id de la animacion de escritura
    const typedId = `typed-${uuidv4()}`;

    // Agregar nuevo thread a la posicion 0
    this.threads.unshift({
      threadId: data.threadId,
      name: data.name,
      created_at: DateTime.now().toISO(),
      typedId: typedId,
    });

    // Establecer total de conversaciones
    this.totalThreads = (this.totalThreads || 0) + 1;

    // Animacion de escritura
    typedAnimation(typedId, data.name, data.threadId);
  }

  ///////////////////////////////////////////////////
  ////////// Documentos

  /**
   * Obtener los documentos de una conversacion
   */
  fetchThreadDocs(threadId: string) {
    this.apiService
      .get(`${this.resources.assistantThread}/${threadId}/documents`, {})
      .pipe(
        untilDestroyed(this),
        takeUntil(this.unsubscribe),
        loadingState(this.loadingStates.addingDocuments)
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            let docs: any[] = [];

            res.data?.forEach((doc: any) => {
              docs.push({
                id: doc.idActivityFile,
                idActivityFile: doc.idActivityFile,
                name: doc.name,
              });
            });

            this.documents = [...new Set(docs)];
          }
        },
        error: (error: any) => {
          // Implementar
        },
      });
  }

  /** Agregar documentos */
  addDocs(docs: ActivityFile[] | any[]) {
    let actualDocs = this.documents || [];
    const parsedDocs: any[] = [];

    docs.forEach((doc: any) => {
      // Si el documento no existe
      if (
        !actualDocs.find(
          (d: any) =>
            (doc.type == 'dossierFile' &&
              d.idActivityFile == doc.idActivityFile) ||
            (doc.type == 'searchObject' &&
              d.openSearchId == doc.openSearchId &&
              d.index == doc.index)
        )
      ) {
        parsedDocs.push({
          id: doc.idActivityFile || doc.openSearchId || doc.id,
          idActivityFile: doc.idActivityFile,
          openSearchId: doc.openSearchId,
          name: doc.name,
          index: doc.index,
          type: doc.type,
          isProcessing: false, // Campo para indicar si está en procesamiento (está siendo vectorizado)
          runId: null, // Campo para almacenar el runId si está en procesamiento
        });
      }
    });

    // Si no se agrego ningún nuevo documento, finalizar
    if (!parsedDocs[0]) return;

    // Si hay una conversacion activa
    if (this.thread?.threadId) {
      this.addThreadDocs(this.thread?.threadId, parsedDocs);
    } else {
      this.addLocalDocs(parsedDocs);
    }

  }

  /** Método para verificar el estado de procesamiento (vectorización) de los documentos */
  private _checkDocsProcessingStatus(docs: any[]) {
    // Los documentos de un asunto se buscan solo por su ID
    const docIds = docs
      .filter((doc) => doc.type == 'dossierFile')
      .map((doc) => doc.id);

    // Consultamos el servicio para obtener los runs asociados a dossierFiles
    if (docIds[0]) {
      this.apiService
        .post(RESOURCES.vectorRun, { id_workspace_files: docIds })
        .subscribe((res: APIResponse<any[]>) => {
          const latestRuns = this._getLatestRuns(res.data);
          latestRuns.forEach((run: any) => {
            if (run.status != 'in_progress') return;
            const doc = docs.find(
              (d) => d.type == 'dossierFile' && d.id === run.id_workspace_file
            );
            if (doc) {
              doc.isProcessing = true;
              doc.runId = run.run_id;
              // Nos suscribimos al evento de socket correspondiente
              this._subscribeToProcessingUpdates(run.run_id);
            }
          });
        });
    }

    // Los search objects se buscan por index y id_open_search
    const searchObjects = docs
      .filter((doc) => doc.type == 'searchObject')
      .map((doc) => ({ index: doc.index, id_open_search: doc.id }));

    if (searchObjects[0]) {
      // Consultamos el servicio para obtener los runs asociados a storageObjects
      this.apiService
        .post(RESOURCES.vectorRun, {
          index_open_search_id_array: searchObjects,
        })
        .subscribe((res: APIResponse<any[]>) => {
          const latestRuns = this._getLatestRuns(res.data);

          latestRuns.forEach((run: any) => {
            if (run.status != 'in_progress') return;
            const doc = docs.find(
              (d) =>
                d.type == 'searchObject' &&
                d.id == run.id_open_search &&
                d.index == run.id_index
            );
            if (doc) {
              doc.isProcessing = true;
              doc.runId = run.run_id;
              // Nos suscribimos al evento de socket correspondiente
              this._subscribeToProcessingUpdates(run.run_id);
            }
          });
        });
    }
  }

  /** Helper para obtener el run más reciente de un archivo */
  private _getLatestRuns(runs: any[]) {
    const latestRunsMap = new Map();
  
    runs.forEach((run) => {
      // Usar id_workspace_file si existe, de lo contrario usar id_open_search y index para crear un key único
      const uniqueKey = run.id_workspace_file
        ? run.id_workspace_file
        : `${run.id_open_search}_${run.id_index}`;
  
      const currentLatest = latestRunsMap.get(uniqueKey);
  
      if (!currentLatest || new Date(run.created_at) > new Date(currentLatest.created_at)) {
        latestRunsMap.set(uniqueKey, run);
      }
    });
  
    return Array.from(latestRunsMap.values());
  }

  // Función para suscribirnos a los eventos de actualización del procesamiento
  private _subscribeToProcessingUpdates(runId: string) {
    const socketEventName = `assistants_update_${runId}`;
    this.socketService.socket?.on(socketEventName, (event: any) => {
      const doc = this.documents.find(d => d.runId == event.payload.run_id);
      if (!doc) return;
      if (event.payload.status === 'success') {
        // El run se completó y se debe actualizar el estado
        doc.isProcessing = false;
        doc.runId = null;
      } else if (event.payload.status === 'failed') {
        // El run fallo y se debe actualizar el estado y mostrar el mensaje de error
        Swal.fire({
          ...TOAST_DEFAULTS,
          text: `El documento ${doc.name || ''} no pudo ser procesado, intenta nuevamente.`,
        })
        doc.isProcessing = false;
        doc.runId = null;
        this.removeDoc(doc)
      }
    });
  }

  /** Agregar documentos a una conversación */
  addThreadDocs(threadId: string, docs: any[]) {
    this.apiService
      .patch(`${this.resources.assistantThread}/${threadId}`, {
        docs,
      })
      .pipe(
        untilDestroyed(this),
        takeUntil(this.unsubscribe),
        loadingState(this.loadingStates.addingDocuments)
      )
      .subscribe({
        next: () => {
          Swal.fire({
            ...TOAST_DEFAULTS,
            icon: 'success',
            text: `${
              docs.length > 1 ? 'Documentos agregados' : 'Documento agregado'
            } correctamente`,
          });
          this._updateDocuments(docs);
        },
      });
  }

  /** Agregar documentos a una nueva conversación */
  addLocalDocs(docs: any[]) {
    this.apiService
      .post(`${this.resources.assistantVectorize}`, { docs })
      .pipe(
        untilDestroyed(this),
        takeUntil(this.unsubscribe),
        loadingState(this.loadingStates.addingDocuments)
      )
      .subscribe({
        next: () => {
          this._updateDocuments(docs);
          Swal.fire({
            ...TOAST_DEFAULTS,
            icon: 'success',
            text: `${
              docs.length > 1 ? 'Documentos agregados' : 'Documento agregado'
            } correctamente`,
          });
        },
      });
  }

  private _updateDocuments(docs: any[]) {
    this.documents = [...new Set((this.documents || []).concat(docs))];
    // Después de agregar todos los documentos, verificamos si están siendo vectorizados
    this._checkDocsProcessingStatus(this.documents);
  }

  /** Eliminar un documento de una conversación*/
  removeDoc(doc: any) {
    // Si hay una conversacion activa
    if (this.thread?.threadId) {
      this.removeThreadDoc(this.thread?.threadId, doc);
    } else {
      this.removeLocalDoc(doc);
    }
  }

  /** Eliminar un documento de una conversacion existente */
  removeThreadDoc(threadId: string, doc: any) {
    Swal.fire({
      ...ALERT_DEFAULTS,
      ...{
        title: 'Confirmación Requerida',
        icon: 'question',
        text: '¿Deseas eliminar el documento de la conversacion?',
        showCancelButton: true,
        showConfirmButton: true,
      },
    }).then((res: SweetAlertResult<any>) => {
      if (res.isConfirmed) {
        this.apiService
          .delete(`${this.resources.assistantThread}/docs/${threadId}`, doc)
          .pipe(
            untilDestroyed(this),
            takeUntil(this.unsubscribe),
            loadingState(this.loadingStates.deletingDocuments)
          )
          .subscribe({
            next: () => this.removeLocalDoc(doc),
          });
      }
    });
  }

  /** Eliminar documentos vectorizados para la nueva conversacion */
  removeLocalDoc(originalDoc: any) {
    this.documents =
      this.documents?.filter((doc: any) =>
        doc.type == 'dossierFile'
          ? doc.idActivityFile != originalDoc.idActivityFile
          : doc.id != originalDoc.id || doc.index != originalDoc.index
      ) || null;
  }
}
