import {
  Component,
  HostListener,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, distinctUntilKeyChanged, filter } from 'rxjs';
import { MENU_ITEMS } from 'src/app/core/constants/menu-items.constants';
import { UserData } from '../../models/user-data';
import { Store, select } from '@ngrx/store';
import { UserDataFull } from '../../reducer/user-data/user-data.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { ValidateAccessDirective } from '../../directives/validate-access.directive';
import { UisrAuthService } from '../../services/uisr-auth.service';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { APIResponse } from '../../interfaces/api.interface';
import { WorkspaceModel } from 'src/app/features/law-firm/models/workspace.models';
import { RESOURCES } from '../../constants/resource-service.constants';
import { ComponentBlocker } from '../../utils/component-blocker';
import { UisrAnalyticsService } from '../../services/analytics.service';


@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgxTippyModule,
    RouterModule,
    MenuItemComponent,
    ValidateAccessDirective,
  ],
})
export class SidebarComponent implements OnInit, OnChanges {
  sidebarExpanded?: boolean;
  menuItems: any = Object.values(MENU_ITEMS);
  userData? = {} as UserData;
  public resources = RESOURCES;


  //Workspace
  oldProfilePic = '';
  workspace: WorkspaceModel | null = null;

  //Loader workspace info
  public loadingWorkspace = new BehaviorSubject(true);

  @Input() sidebarOpen: boolean = false;

  @HostListener('document:keydown.escape', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.sidebarOpen = false;
      localStorage.setItem('sidebarExpanded', 'false');
    }
  }

  @HostListener('window:resize', []) onWindowResize() {
    this.updateSidebarStatus();
  }

  constructor(
    private store: Store,
    private authService: UisrAuthService,
    private router: Router, 
    public apiService: UisrApiServiceV2,
    private blocker: ComponentBlocker,
    private analyticsService: UisrAnalyticsService,
  ) {
    this.subscribeToLoader();
    this.store
      .pipe(
        select(UserDataFull),
        distinctUntilKeyChanged('idWorkspace'),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.userData = data;
      });

    this.authService.menuOptions.subscribe((data) => {
      this.menuItems = data;
      if (this.userData?.idWorkspace) {
        this.menuItems = Object.values(this.menuItems).filter(
          (item: any) =>
            item.label != 'CLIENT_PORTAL_STRING' && item.url != '/law-firm/cliente-files'
        );
      }else{
        this.menuItems = Object.values(this.menuItems).filter(
          (item: any) =>
            item.label == 'CLIENT_PORTAL_STRING' && item.url  == '/law-firm/cliente-files' 
        );
      }
    });

  }

  ngOnInit(): void {
    window.addEventListener('workspaceNameChanged', this.setInitialData.bind(this));
    window.addEventListener('workspaceImageChanged', this.setInitialData.bind(this));

    if (this.userData?.idWorkspace || this.userData?.id_workspace_member) {
      this.setInitialData()
    }

    this.updateSidebarStatus();
    this.sidebarExpanded = localStorage.getItem('sidebarExpanded')
      ? localStorage.getItem('sidebarExpanded') == 'true'
      : true;
    this.sidebarOpen = localStorage.getItem('sidebarOpen')
      ? localStorage.getItem('sidebarOpen') == 'true'
      : true;
    this.expandOrCollapseSidebar(this.sidebarExpanded);

    this.authService.sidebarPreferences();

    this.router.events
      .pipe(
        filter((event: any) => {
          return event.routerEvent instanceof NavigationEnd;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => (this.updateSidebarStatus()));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes['sidebarOpen'] &&
      changes['sidebarOpen'].currentValue != undefined
    ) {
      localStorage.setItem(
        'sidebarOpen',
        changes['sidebarOpen'].currentValue.toString()
      );
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('workspaceNameChanged', (event) => this.setInitialData.bind(this));
    window.removeEventListener('workspaceImageChanged', (event) => this.setInitialData.bind(this));
  }

  updateSidebarStatus() {
    if (window.innerWidth >= 1535) {
      this.sidebarExpanded = true;
      this.expandOrCollapseSidebar(true);
    } else if (window.innerWidth < 1024) {
      this.sidebarOpen = false;
      localStorage.setItem('sidebarOpen', 'false');
    } 
    
    // if (this.userData?.idWorkspace) {  
    //   this.menuItems = Object.values(MENU_ITEMS).filter(
    //     (item: any) =>
    //       item.label != 'CLIENT_PORTAL_STRING' && item.url != '/law-firm/cliente-files'
    //   );
    // }
  }

  changeSidebarStatus(): void {
    this.sidebarExpanded = !this.sidebarExpanded;
    this.expandOrCollapseSidebar(this.sidebarExpanded);
  }

  expandOrCollapseSidebar(isOpen: boolean): void {
    if (isOpen) {
      document.querySelector('body')!.classList.add('sidebar-expanded');
      localStorage.setItem('sidebarExpanded', 'true');
    } else {
      document.querySelector('body')!.classList.remove('sidebar-expanded');
      localStorage.setItem('sidebarExpanded', 'false');
    }
  }

  openOrCloseSidebar(): void {
    this.sidebarExpanded = true;
    this.expandOrCollapseSidebar(true);
    setTimeout(() => {
      this.sidebarOpen = !this.sidebarOpen;
      localStorage.setItem('sidebarOpen', this.sidebarOpen.toString());
    }, 0);
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  setInitialData() {
    this.apiService
      .get(
        `${this.resources.workspace}/${this.userData?.idWorkspace || this.userData?.id_workspace_member
        }`,
        null
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: async (res: APIResponse<WorkspaceModel>) => {
          if (res.success) {
            this.oldProfilePic = res.data.photoWorkspace || '';
            this.workspace = res.data;
          }
        },
      });
  }

  subscribeToLoader() {
    this.blocker.loaderAll.subscribe((data) => {
      const paths = data.global?.paths || [];
      this.loadingWorkspace.next(
        paths.includes(`${this.resources.workspace}/${this.userData?.idWorkspace || this.userData?.id_workspace_member
          }`) || false
      );
    });
  }

  /** Envía el evento de click en la opción de configuración del despacho a GA */
  trackSettingsClick() {
    this.analyticsService.trackMenuClick('Configuración Despacho', this.userData!);
  }


}
