import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { MarkdownService } from 'ngx-markdown';
import { distinctUntilKeyChanged } from 'rxjs';
import { UserData } from 'src/app/core/models/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { AppTutorialService } from 'src/app/core/services/tutorial.service';
import { AssistantChatService } from '../../services/assistant-chat.service';

@UntilDestroy()
@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})
export class ChatWindowComponent {
  tutorialService = inject(AppTutorialService);
  assistantChatService = inject(AssistantChatService);
  userData?: UserData
  infoContent = '';
  chips = [
    {
      label: '¿Cómo crear una plantilla?',
      icon: 'fa-solid fa-edit',
      prompt: 'Proporciona una explicación detallada y paso a paso sobre cómo crear una plantilla para asuntos dentro de la plataforma. Incluye información sobre cómo acceder a la función de creación de plantillas, qué elementos se pueden personalizar, cómo guardar y nombrar la plantilla, y cómo aplicarla a futuros asuntos. Además, menciona cualquier consejo o mejor práctica para crear plantillas efectivas que optimicen el flujo de trabajo en el manejo de asuntos legales.',
    },
    {
      label: '¿Cómo crear un cliente?',
      icon: 'fa-solid fa-file-signature',
      prompt: 'Proporciona una explicación detallada y paso a paso sobre cómo crear un nuevo cliente dentro de la plataforma. Incluye información sobre cómo acceder a la función de creación de clientes, qué datos se deben ingresar y cómo personalizar la ficha del cliente. Además, menciona cualquier consejo o mejor práctica para organizar la información de los clientes de manera eficiente y asegurar que se pueda acceder fácilmente a sus datos en el futuro para el manejo de asuntos legales.',
    },
    {
      label: '¿Cómo crear un asunto?',
      icon: 'fa-solid fa-folder-open',
      prompt: 'Proporciona una explicación detallada y paso a paso sobre cómo crear un nuevo asunto en la plataforma. Incluye información sobre cómo acceder a la función de creación de asuntos, qué campos y detalles son necesarios para completar el proceso, cómo asignar responsables y fechas relevantes, y cómo vincular documentos o información relacionada al asunto. Además, menciona cualquier consejo o mejor práctica para organizar y categorizar los asuntos de manera efectiva dentro del sistema, asegurando una gestión eficiente de los casos legales en la plataforma. Incluye información relevante sobre la selección y utilización de plantillas',
    },
    {
      label: 'Resumir Documento',
      icon: 'fa-solid fa-file-alt',
      prompt: 'Realiza un resumen detallado y conciso del documento adjunto, destacando los puntos clave, las ideas principales y cualquier información relevante que sea fundamental para comprender el contenido del texto. Asegúrate de capturar la esencia del documento de manera clara y coherente.',
    },
  ];

  private readonly store = inject(Store);
  private readonly markdownService = inject(MarkdownService)

  @ViewChild('messagesWrapper') messagesWrapper!: ElementRef;

  constructor() {
    this.assistantChatService.chatWindowComponent = this;

    // Suscribirse a los datos del usuario
    this.store
    .pipe(
      select(UserDataFull),
      distinctUntilKeyChanged('id_users'),
      untilDestroyed(this)
    )
    .subscribe(async (data) => {
      this.userData = data;
    });

    this.infoContent = this.markdownService.parse(`
      ## Bienvenido a Amparo IA en MiDespacho
      
      Amparo IA es tu asistente legal inteligente, diseñado para ayudarte a gestionar y simplificar tu trabajo en la plataforma MiDespacho. A continuación, te presentamos las capacidades actuales de Amparo IA y las novedades que estamos preparando para mejorar tu experiencia.

      **🚀 Capacidades Actuales de Amparo IA**

      1.	**Interpretación de Documentos**: Amparo IA puede responder preguntas específicas sobre el contenido de documentos anexados en tus expedientes (.pdf, .docx, .xlsx, etc.) y tus colecciones (sentencias, leyes, etc.), facilitando la obtención de información relevante sin necesidad de revisar el documento completo.

        - **Inclusión de Bibliografía**: Amparo IA facilita la verificación e inclusión de referencias bibliográficas en los documentos que generes.
      
      2.	**Resumen de Documentos**: Optimiza tu tiempo con la función de resumen de documentos, permitiendo obtener las ideas clave de un texto sin necesidad de leerlo en su totalidad.

      3.	**Asistencia Legal Básica**: Amparo IA está equipado para asistirte en temas legales básicos, ofreciéndote soporte y orientación rápida sobre cuestiones rutinarias.

      4.	**Tutoriales de Plataforma**: Explora tutoriales interactivos que te mostrarán cómo aprovechar al máximo las funciones de MiDespacho, incluido el uso de Amparo IA.
            
      5.	**Módulo de Ajustes Personalizados**: Personaliza la configuración de Amparo IA según tus preferencias y necesidades específicas.

      **⏰ Próximamente en Amparo IA**

      Estamos trabajando para ampliar las capacidades de Amparo IA con nuevas funciones que mejorarán aún más tu flujo de trabajo:

      1.	**Compartir Chats con Colaboradores**: Mejora la colaboración permitiendo compartir chats e interacciones con Amparo IA directamente con tus colaboradores.

      2.  **Selección y Generación de Plantillas (machotes)**: Utiliza Amparo IA para generar plantillas (machotes) de tus documentos legales como contratos, acuerdos, autos, demandas, denuncias, escritos, etc.

      **🔮 A Futuro**

      Mirando hacia el futuro, planeamos integrar funcionalidades avanzadas que transformarán tu manera de trabajar:

      1.	**Gestión de Eventos y Recordatorios en el Calendario**: Amparo IA te ayudará a organizar tu agenda, configurando eventos y recordatorios directamente desde la plataforma.

      2.	**Búsquedas de Leyes y Precedentes**: Accede a una base de datos completa de leyes, sentencias, jurisprudencias y citas legales directamente a través de Amparo IA.

      3.  **Agentes legales (pasantes virtuales)**: Realiza tareas complejas utilizando a un pasante virtual que se encargue de buscar, redactar, transcribir y mejorar argumentos. Todo bajo tu supervisión, mientras tú te enfocas en tareas más sofisticadas.

      Amparo IA está aquí para asistirte y hacer que tu práctica legal sea más eficiente. ¡Explora todas estas funciones y prepara tu despacho para el futuro con MiDespacho!
    `);

  }

  /** Envía solicitud para actualizar el input del chat con el prompt del chip seleccionado */
  onChipSelection(chip: any) {
    this.assistantChatService.showChips = false;
    this.assistantChatService.updateMessage.next(chip.prompt);
  }
  
  /**
   * Comprobar si el listado de conversasiones debe cargar automaticamente mas conversaciones
   */
	checkThreadMessagesScroll() {
		const container = this.messagesWrapper.nativeElement;

		if (container.scrollHeight <= container.clientHeight && !this.assistantChatService.loadingStates.loadingThread.value && !this.assistantChatService.loadingStates.loadingThreadMessages.value) {
      this.assistantChatService.fetchNextMessages();
		}
	}

  /**
   * Calcular si el mensaje actual esta en el dia siguiente con respecto al anterior
   */
  isNewDayMessage(actualDate: string, previousDate: string) {

    // Prevenir error de consultar indice inexistente anterior
    // o error de los mensajes nuevos (salientes) que no tienen "created_at"
    let actualDateS = actualDate
      ? DateTime.fromISO(actualDate).toLocal().toSeconds()
      : DateTime.now().toSeconds();
    let previousDateS = previousDate
      ? DateTime.fromISO(previousDate).toLocal().toSeconds()
      : 1000000000;

    let aDate = DateTime.fromISO(
      DateTime.fromSeconds(actualDateS).toISODate() || ''
    );

    let pDate = DateTime.fromISO(
      DateTime.fromSeconds(previousDateS).toISODate() || ''
    );

    let sDate = aDate.setLocale('es').toFormat("cccc, d 'de' LLLL");
    let sTime = DateTime.fromSeconds(actualDateS)
      .setLocale('en')
      .toLocaleString(DateTime.TIME_SIMPLE);

    return {
      condition: aDate > pDate,
      date: sDate,
      time: sTime,
    };
  }

  /** Imagen del usuario */
  get profilePicture() {
    return this.userData?.selfie ? this.userData?.selfie : '/assets/images/profile-placeholder.png';
  }

}
