import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import EJ2_LOCALE from 'src/assets/ej2/ej2-es.json';
import { environment } from 'src/environments/environment';
import { RESOURCES } from '../../constants/resource-service.constants';
import { UserData } from '../../models/user-data';
import { UserDataFull } from '../../reducer/user-data/user-data.selector';
import { NgSelectConfig } from '@ng-select/ng-select';
import { IntercomService } from 'src/app/shared/services/intercom.service';
import { isEqual } from 'lodash';

L10n.load({ es: EJ2_LOCALE.es });
setCulture('es');

declare var gtag: (
  arg0: string,
  arg1: string,
  arg2: { page_path: string; user_id: any }
) => void;

declare global {
  interface Window {
      Intercom:any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'mi-despacho-app';
  lastVisitedUrl: string | null = null;
  navigationStartTime: number | null = null;
  navigationEndTime: number | null = null;
  totalTime: number | null = null;
  resources = RESOURCES;
  userData?: UserData;

  private readonly _ngSelectConfig = inject(NgSelectConfig);

  private unsubscribe = new Subject<void>();

  private readonly _intercomService = inject(IntercomService);

  constructor(private router: Router, private store: Store) {
    this._ngSelectConfig.notFoundText = 'No se encontraron resultados';
    this._ngSelectConfig.placeholder = 'Selecciona una opción';
    this._ngSelectConfig.typeToSearchText = 'Escribe para buscar';

    this.store
      .pipe(select(UserDataFull), distinctUntilChanged(isEqual), takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.userData = data;
      });

    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.idGA, {
            page_path: event.urlAfterRedirects,
            user_id: this.userData?.id_users || null,
          });

          this._intercomService.updateIntercom();
        }
      },
    });
  }
}
