import { inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { distinctUntilChanged, Subject } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { SUBSCRIPTION_STATUS } from 'src/app/core/constants/subscription-status.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UserData } from 'src/app/core/models/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { UisrApiServiceV2 } from './uisr-api.service-v2';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private subscription = new Subject();
  private readonly store = inject(Store);
  private readonly _apiService = inject(UisrApiServiceV2);

  constructor() {
    this._subscribeToUserSubscription();
    this._getSubscription();
    // this._subscribeToUserData();
  }

  private _getSubscription() {
    this._apiService
      .get(RESOURCES.lastSubscriptionByUser)
      .pipe(untilDestroyed(this))
      .subscribe((res: APIResponse<any>) => {
        this.subscription.next(res.data);
      });
  }

  /** Al obtener la suscripción del usuario se verifica si se debe mostrar el botón de intercom fuera del modal de contacto */
  private _subscribeToUserSubscription() {
    this.subscription
      .pipe(distinctUntilChanged(isEqual), untilDestroyed(this))
      .subscribe({
        next: (subscription: any) => {
          if (
            subscription &&
            (subscription.fkIdStatusSuscrip == SUBSCRIPTION_STATUS.TRIALING || // Mostrar a los que están en pruebas
              subscription.fkIdStatusSuscrip == SUBSCRIPTION_STATUS.CANCELLED || // Mostrar a los que están cancelados
              !subscription.stripeObject?.default_payment_method) // Mostrar a los que no tienen un método de pago guardado
          ) {
            window.Intercom('update', {
              hide_default_launcher: false,
            });
          } else {
            window.Intercom('update', {
              hide_default_launcher: true,
            });
          }
        },
      });
  }

  private _subscribeToUserData() {
    this.store
      .pipe(
        select(UserDataFull),
        distinctUntilChanged(isEqual),
        untilDestroyed(this)
      )
      .subscribe((data?: UserData) => {
        if (data && data.id_users) {
          this.updateIntercom({
            email: data.email,
            user_id: data.id_users,
            name: data.first_name,
          });
        }
      });
  }

  bootIntercom(data: any): void {
    window.Intercom('boot', { ...data, hide_default_launcher: true });
  }

  shutdownIntercom(): void {
    window.Intercom('shutdown');
  }

  showIntercom(): void {
    window.Intercom('show');
  }

  updateIntercom(data?: any): void {
    const lastRequestAt = Math.floor(new Date().getTime() / 1000);
    window.Intercom('update', { last_request_at: lastRequestAt, ...data });
  }
}
